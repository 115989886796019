import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./home/home.module').then( m => m.HomePageModule)
  },
  {
    path: 'destination/:city',
    loadChildren: () => import('./pages/destination/destination.module').then( m => m.DestinationPageModule)
  },
  {
    path: 'destinations',
    loadChildren: () => import('./pages/all-destinations/all-destinations.module').then( m => m.AllDestinationsPageModule)
  },
  {
    path: 'destination/:city/:hotelname',
    loadChildren: () => import('./pages/hotel/hotel.module').then( m => m.HotelPageModule)
  },
  {
    path: 'privacy',
    loadChildren: () => import('./pages/privacy/privacy.module').then( m => m.PrivacyPageModule)
  },
  {
    path: 'about',
    loadChildren: () => import('./pages/about/about.module').then( m => m.AboutPageModule)
  }
];

@NgModule({
  imports: [ RouterModule.forRoot(routes, {
    initialNavigation: 'enabledBlocking'
}) ],
  exports: [ RouterModule ]
})
export class AppRoutingModule {}
