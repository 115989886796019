export const environment = {
  firebase: {
    projectId: 'happy-hotels',
    appId: '1:690044962958:web:eb437a65e94ddee82461df',
    storageBucket: 'happy-hotels.appspot.com',
    // locationId: 'europe-central2',
    apiKey: 'AIzaSyBKI-L5l91MxAO9dwA3apVMV0dx_bgNeN0',
    authDomain: 'happy-hotels.firebaseapp.com',
    messagingSenderId: '690044962958',
    measurementId: 'G-S6LHNTXY38',
  },
  appVersion: require('../../package.json').version,
  production: true,
  hmr: false,
  searchEndpoint: "http://localhost:8081/data/get-city-hotels?query=",
  defaultLang: "en",
  firebaseConfig: {
    apiKey: "AIzaSyBKI-L5l91MxAO9dwA3apVMV0dx_bgNeN0",
    authDomain: "happy-hotels.firebaseapp.com",
    projectId: "happy-hotels",
    storageBucket: "happy-hotels.appspot.com",
    messagingSenderId: "690044962958",
    appId: "1:690044962958:web:eb437a65e94ddee82461df",
    measurementId: "G-S6LHNTXY38"
  }
};
